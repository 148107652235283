import {permitApplicationFieldsUtil} from "permitapplication/utilities";

const permitUtil = permitApplicationFieldsUtil;
const createNotifications = permitUtil.createNotifications;

const getHolderStepFields = permit =>
    createNotifications([["Selecione o titular.", permit.holder]]);

const getDocStepRequiredFields = permit => {
    const basicFields = createNotifications([
        ["Selecione as guias da autorização.", !!permit.types.length],
        [
            "Salve a folha de solicitação preenchida e firmada pelo solicitante.",
            !!permit.application_doc,
        ],
        [
            "Salve o BI/Cartão de residência (particular) ou o cartão fiscal (empresa).",
            !!permit.personal_id_doc || !!permit.tax_id_doc,
        ],
        ["Salve o título de terras.", !!permit.plot_ownership_doc],
    ]);

    const plotUseAuthorizationField = createNotifications([
        [
            "Salve a declaração de autorização de posse/aproveitamento de terra.",
            !!permit.plot_use_authorization,
        ],
    ]);

    return permit.is_the_plot_owner
        ? basicFields
        : [...basicFields, ...plotUseAuthorizationField];
};

const getAnalysisStepFields = permit => {
    const basicFields = createNotifications([
        [
            "Valide a folha de solicitação preenchida e firmada pelo solicitante.",
            !!permit.application_doc_valid,
        ],
        ["Valide o título de terras.", !!permit.plot_ownership_doc_valid],
    ]);

    const personalIdDocField = createNotifications([
        ["Valide o BI/Cartão de residência.", !!permit.personal_id_doc_valid],
    ]);
    const taxIdDocField = createNotifications([
        ["Valide o Cartão Fiscal.", !!permit.tax_id_doc_valid],
    ]);
    const plotUseAuthorizationField = createNotifications([
        [
            "Valide a declaração de autorização de posse/aproveitamento de terra.",
            !!permit.plot_use_authorization_valid,
        ],
    ]);

    const additionalFields = [
        ...(permit.personal_id_doc ? personalIdDocField : []),
        ...(permit.tax_id_doc ? taxIdDocField : []),
        ...(!permit.is_the_plot_owner ? plotUseAuthorizationField : []),
    ];

    return [...basicFields, ...additionalFields];
};

const getPlotStepFields = permit =>
    createNotifications([["Selecione a parcela", !!permit.plot]]);

const getVisitStepFields = permit =>
    createNotifications([
        ["Acrescente a data da vistoria", !!permit.inspection_date],
        ["Acrescente a equipa que realizou a vistoria", !!permit.inspection_team],
    ]);

const getTypesStepRequiredFields = permit => {
    const fieldsByType = {
        felling: [
            [
                "Acrescente os dados da guia de abate.",
                !!permit.felling_start_date || !!permit.felling_end_date,
            ],
            [
                "Acrescente as árvores do abate.",
                permitUtil.checkTrees(permit?.tree_set, "felling"),
            ],
        ],
        cutting: [
            [
                "Acrescente os dados da guia de serragem.",
                !!permit.cutting_start_date || !!permit.cutting_end_date,
            ],
            [
                "Acrescente as árvores da serragem.",
                permitUtil.checkTrees(permit?.tree_set, "cutting"),
            ],
        ],
        clearing: [
            [
                "Acrescente os dados da guia de desbravamento.",
                !!permit.clearing_start_date ||
                    !!permit.clearing_end_date ||
                    !!permit.clearing_area,
            ],
        ],
        transport: [
            [
                "Acrescente os dados da guia de transporte.",
                !!permit.transport_start_date || !!permit.transport_end_date,
            ],
            ["Acrescente os materiais a transportar.", !!permit.material_set.length],
        ],
    };

    const requiredFields = permit.types?.flatMap(type =>
        fieldsByType[type] ? createNotifications(fieldsByType[type]) : []
    );

    return requiredFields || [];
};

const getReportStepFields = permit =>
    createNotifications([
        ["Salve o relatório da vistoria.", !!permit.technical_report],
        ["Indique o parecer técnico.", !!permit.technical_decision],
    ]);

const getFeesStepRequiredFields = permit => {
    const fieldsByType = {
        felling: [["Acrescente a taxa de abate das árvores.", !!permit.felling_price]],
        cutting: [
            ["Acrescente a taxa de serragem das árvores.", !!permit.cutting_price],
        ],
        clearing: [["Acrescente a taxa de desbravamento.", !!permit.clearing_fee]],
        transport: [
            [
                "Acrescente a taxa de transporte dos materiais.",
                permitUtil.checkFeesInMaterials(permit?.material_set),
            ],
        ],
    };

    const requiredFields = permit.types?.flatMap(type =>
        fieldsByType[type] ? createNotifications(fieldsByType[type]) : []
    );

    return requiredFields || [];
};

const getInvoiceStepFields = permit =>
    createNotifications([
        ["Imprima a factura.", !!permit.printed_invoice],
        ["Indique si a factura foi enviada para o/a titular.", !!permit.invoice_sent],
    ]);

const getPaymentStepFields = permit =>
    createNotifications([
        ["Imprima a autorização.", !!permit.printed_permit],
        ["Salve o comprovativo de pagamento.", !!permit.payment_receipt],
    ]);

const getSignatureStepFields = permit =>
    createNotifications([
        [
            "Indique si a solicitação foi enviada para a Direcção.",
            !!permit.application_sent,
        ],
        ["Indique si a Direcção assinou a solicitação.", !!permit.application_signed],
    ]);

export const getRequiredFields = permit => ({
    holder_s: getHolderStepFields(permit),
    permit_s: getDocStepRequiredFields(permit),
    analysis_m: getAnalysisStepFields(permit),
    plot1_m: getPlotStepFields(permit),
    visit_m: getVisitStepFields(permit),
    permit_m: getTypesStepRequiredFields(permit),
    report_m: getReportStepFields(permit),
    payment_e: getFeesStepRequiredFields(permit),
    invoice_s: getInvoiceStepFields(permit),
    payment_s: getPaymentStepFields(permit),
    signature_s: getSignatureStepFields(permit),
});
