import {FormDatePickerCombo, FormInputText} from "base/form/components";
import Grid from "@mui/material/Grid";

const PermitApplicationTransportFormFields = ({disabled}) => {
    return (
        <Grid container columnSpacing={1}>
            <FormDatePickerCombo
                startDateName={"transport_start_date"}
                startDateLabel={"Data de início"}
                endDateName={"transport_end_date"}
                endDateLabel={"Data de caducidade"}
                required={true}
                disabled={disabled}
            />
            <Grid item xs={12} sm={6}>
                <FormInputText
                    name="transport_beneficiary"
                    label="Beneficiário/a"
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormInputText
                    name="transport_destination"
                    label="Destino"
                    disabled={disabled}
                />
            </Grid>
        </Grid>
    );
};

export default PermitApplicationTransportFormFields;
